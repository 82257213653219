import React from 'react';
import { EngagementList } from '../helpers/EngagementList';
import EngagementItems from '../helpers/EngagementItem';
import '../styles/engagement.css'

function engagements() {
  return (
    <div>
      <div className='engagement-image'>
        <h1>Engagements</h1>
      </div>
      <div className='engagements-page-container'>
      <div className='engagements-list'>
        {EngagementList.map((EngagementItem, key) => {
            return (
            <EngagementItems 
              key={key}
              title={EngagementItem.title}
              role={EngagementItem.role}
              location={EngagementItem.location}
              date={EngagementItem.date}
            />
            );
        })}
      </div>
      <div className='engagements-block'>
         
      </div>
      </div>
    </div>
  )
}

export default engagements
