import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar.js';
import Form from '../form.js';
import '../styles/home.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import backgroundImage from '../assets/all_black.jpg';
import headshot from '../assets/headshot2.jpg';
import bg from '../assets/kneelingWDude.jpg';
import outdoors from '../assets/outdoors.jpg';
import engagements from './engagements.js';


function home() {
  return (
    
    <div>
      <div className='monogram'>
        <div className='mg'>
          <div className='text'>
            <div className='home-banner-logo'>

            </div>
            <h2>Olivia Helman</h2>
            <h3> Soprano</h3>
          </div>
        </div>
      </div>
      <div className='bio'>
      <div className='br'>
        <div className='block'>          
        </div>
      </div>
      
      <div className='bio-text'>
        <h1>BIO</h1>
        <div className='bio-text-container'>
        <p>Embark on a musical journey with me as I pursue my Master of Music in Vocal Performance and Musicology at the University of Minnesota. With a rich background in Vocal Performance, Music History, and Literature from Baldwin Wallace Conservatory, I've participated in numerous operas and recitals, honing my skills and passion along the way.

My dedication to musical excellence has been recognized through prestigious awards such as the NATS Great Lakes Regional First Place and the Martha Goldsworthy Arnold Talent Scholarship. Click to delve deeper into my musical accomplishments, experiences, and aspirations, and join me in celebrating the power and beauty of music.</p>
        <button className='read-more'><Link to='/about'>Read More</Link></button>
        </div>
      </div>
      </div>
      <div className='engagement-container'>
        <div className='appearences'>
          <div className='title'>
            <h1>2024</h1>
            <h3>Appearances</h3>
          </div>
          <div className='appearances-text'>
            <p>My musical journey is woven with captivating performances that showcase my passion and dedication to the art of vocal performance. At Baldwin Wallace Conservatory, I immersed myself in the world of opera, participating in 8 captivating productions that allowed me to embody diverse characters and stories on stage.

Additionally, I have delighted audiences with 4 solo recitals, where I showcased my vocal range and interpretive skills, drawing listeners into the emotional depths of each musical piece. These performances have not only honed my technical abilities but have also deepened my connection to the music and its profound impact on both performers and audiences alike.

Whether through the grandeur of operatic roles or the intimacy of solo recitals, my performances are a testament to my commitment to excellence and my enduring love for the transformative power of music.

Explore my performances to experience the magic of music brought to life on stage.</p>
          </div>
          <div className='events-conatainer'>
            <div className='event'>
              <div className='title-container'>
                <h2>Il Trovatore</h2>
                <h3>Ines</h3>
              </div>
              <div className='location'>
                <h3>University of Minnesota</h3>
              </div>
              <div className='date'>
                <h3>2024</h3>
                <Link to='/engagements'><button>Tickets</button></Link>
              </div>
            </div>
            <div className='event'>
              <div className='title-container'>
                <h2>The Marriage of Figaro</h2>
                <h3>Susanna</h3>
              </div>
              <div className='location'>
                <h3>Baldwin Wallace</h3>
              </div>
              <div className='date'>
                <h3>2022</h3>
                <Link to='/engagements'><button>Tickets</button></Link>
              </div>
            </div>
            <div className='event'>
              <div className='title-container'>
                <h2>Iphigenie en Aulide</h2>
                <h3>Iphigenie</h3>
              </div>
              <div className='location'>
                <h3>Baldwin Wallace</h3>
              </div>
              <div className='date'>
                <h3>2022</h3>
                <Link to='/engagements'><button>Tickets</button></Link>
              </div>
            </div>
          </div>

          <div className='btn-container'>
          <button className='schedule-button'><Link to='/engagements'>Full Schedule</Link></button>
          </div>
        </div>
        <div className='image-olivia'>
            <div className='actual-image'>
              
            </div>
        </div>
      </div>
      <div className='media-container'>
        <div className='left-side'>
           
        </div>
        <div className='right-side'>
          <h1>Media</h1>
          <h3>Pictures & Videos</h3>
          <button><Link to='/media'>View all</Link></button>
        </div>
      </div>
      <div className='contact-container'>
        <div className='contact'>
          <div className='left'>
            <div className='logos'>
              <div className='iconOne'><a href='https://www.instagram.com/0liviaah?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><InstagramIcon className='icon'/></a></div>
              <div className='iconTwo'><a href='https://www.facebook.com/olivia.helman.146/'><FacebookIcon className='icon'/></a></div>
              <div className='iconTree'><YouTubeIcon className='icon'/></div>             
            </div>
          </div>
          <div  className='righty'>
            <h1>Contact Me</h1>
          <Form />
          </div>
        </div>
      </div>
      <div className='bottom-image'>
        
      </div>
    </div>

    

  )
}

export default home
