export const EngagementList = [

{
    title: 'Il Trovatore',
    role: 'Ines',
    location: 'University of Minnesota',
    date: 2024,
},

{
    title: 'The Marriage of Figaro',
    role: 'Susanna',
    location: 'Baldwin Wallace Conservatory',
    date: 2022,
},
{
    title: 'L\'incoronazione di Poppea',
    role: 'Drusilla',
    location: 'Baldwin Wallace Conservatory',
    date: 2022,
},

{
    title: 'Iphigenie en Aulide',
    role: 'Iphigenie',
    location: 'Baldwin Wallace Conservatory',
    date: 2022,
},

{
    title: 'The Men I am Not Married To',
    role: 'Evelyn',
    location: 'Baldwin Wallace Conservatory',
    date: 2021,
},
{
    title: 'Alcina',
    role: 'Alcina',
    location: 'Baldwin Wallace Conservatory',
    date: 2021,
},

{
    title: 'Don Giovanni "Ah! Del padre in periglio!"',
    role: 'Donna Anna',
    location: 'Baldwin Wallace Conservatory',
    date: 2021,
},
{
    title: 'The Men I am Not Married To',
    role: 'Evelyn',
    location: 'Baldwin Wallace Conservatory',
    date: 2021,
},
{
    title: 'Princess Ida "Now wouldn\'t you like to rule the roost?"',
    role: 'Melissa',
    location: 'Baldwin Wallace Conservatory',
    date: 2018,
}
];