import React, {useState} from "react";

function ContactForm() {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch('https://formspree.io/f/xyyrwrkl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        if (response.ok) {
          alert('Form submitted successfully!');
          setFormData({
            email: '',
            message: ''
          });
        } else {
          throw new Error('Form submission failed');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form. Please try again later.');
      }
    };
  
    return (
      <form onSubmit={handleSubmit} className='contact-form'>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-control"/>
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} className="form-control"></textarea>
        </div>
        <button type="submit" className='btn btn-primary' >Send Message</button>
      </form>
    );
}

export default ContactForm