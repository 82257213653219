import React from 'react'
import { Link } from 'react-router-dom';
import './styles/navbar.css';

function navbar() {
  return (
    <nav className="navbar">
        <div className="left-container">
            <div className='left'>
            <h1>Olivia Helman</h1>
            </div>
            <div className='middle'>
                <h3>Soprano</h3>
            </div>
        </div>
        <div className="right">
            <Link to='/'> Home </Link>
            <Link to='/about'> About </Link>
            <Link to='/engagements'> Engagements </Link>
            <Link to='/media'> Media </Link>
            <Link to='/contact'> Contact </Link>
            <div className='navbar-logo'></div>
        </div>
    </nav>
  )
}

export default navbar
