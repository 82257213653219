import React from 'react'
import '../styles/about.css'

function about() {
  return (
    <div>
      <div className='about-image'>
        <h1>Biography</h1>
      </div>
      <div className='about-text'>
        <p>I am currently pursuing my Master of Music in Vocal Performance and Musicology at the University of Minnesota, where I am deeply immersed in the study and exploration of music's rich tapestry. My journey in music began at Baldwin Wallace Conservatory, where I majored in Vocal Performance, Music History, and Literature during my undergraduate years. At BW, I had the incredible opportunity to participate in 8 operas and 4 recitals, which not only refined my technical skills but also deepened my appreciation for the art form.

Throughout my musical career, I have been fortunate to receive recognition for my dedication and talent. In 2020, I was honored to receive the First Place Award at the NATS Great Lakes Regional Conference, a testament to my commitment and passion for vocal performance. The previous year, I earned an Honorable Mention from the Dayton Opera Guild, further motivating me to strive for excellence in every musical endeavor.

One of the highlights of my musical journey was being awarded the prestigious Martha Goldsworthy Arnold Talent Scholarship, a recognition that spans from 2021 to 2023 and serves as a validation of my potential and dedication to the craft. Additionally, in 2022, I had the privilege of participating in the esteemed Music Across the Pond program, broadening my musical horizons and collaborating with talented artists from around the world.

As I continue my studies and artistic exploration, I am committed to sharing my love for music with audiences and fellow musicians alike. Music has the power to inspire, uplift, and connect us on a profound level, and I am dedicated to contributing to its enduring legacy through my performances, research, and musical contributions.</p>

        <div className='image'>

        </div>
      </div>
    </div>
  )
}

export default about
