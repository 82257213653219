import React, { useState } from 'react';
import '../styles/engagementItem.css'


function EngagementItem({ title, role, location, date }) {
  const [message, setMessage] = useState('');
  
  const displayMessage = () => {
    setMessage('No tickets available');
  };

  return (
    <div className='engagementItem'>
      <div className='engagement-titles'>
        <h1>{title}</h1>
        <h3>{role}</h3>
      </div>
      <div className='engagement-location'>
        <h2>{location}</h2>
      </div>
      <div className='engagement-date'>
        <h2>{date}</h2>
      </div>
      <div className='ticket-container'>
        {message && <div className='message-error'>{message}</div>}
        <button onClick={displayMessage}>Tickets</button>
      </div>
    </div>
  );
}

export default EngagementItem;
