import React from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import './styles/footer.css'

function footer() {
  return (
    <div className='footer'>
      <div className='top'>
        <h1>Olivia Helman</h1>
        <h2>Soprano</h2>
      </div>
      <div className='footer-bottom'>
        <div className='left-footer'>
            <h3>Menu</h3>
            <div className='inLineTwo'>
                <Link to='/'> Home </Link>
                <Link to='/about'> about </Link>
                <Link to='/engagements'> engagements </Link>
                <Link to='/media'> media </Link>
                <Link to='/contact'> contact </Link>
            </div>
        </div>
        <div className='right-footer'>
            <h3>Connect</h3>
            <div className='inLine'>
                <a href='https://www.instagram.com/0liviaah?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='><InstagramIcon/></a>
                <a href='https://www.facebook.com/olivia.helman.146/'><FacebookIcon /></a>
                <YouTubeIcon />
            </div>
            <p>&copy; Olivia Helman</p>

        </div>
      </div>
    </div>
  )
}

export default footer
