import React from 'react'
import Form from '../form.js'
import '../styles/contact.css'

function contact() {
  return (
    <div>
      <div className='contact-banner'>
        <h1>Contact Me</h1>
      </div>
      <div className='contact-content-container'>
        <div className='contact-image'>

        </div>
        <div className='contact-text'>
          <h2>Phone: 740-616-7834</h2>
          <h2>Email: olivhelman@gmail.com</h2>
          <Form />
        </div>
        <div className='contact-block'></div>
      </div>

    </div>
  )
}

export default contact
