import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './navbar.js'; 
import Home from './pages/home';
import About from './pages/about.js';
import Engagements from './pages/engagements.js';
import Media from './pages/media.js';
import Contact from './pages/contact.js';
import Footer from './footer.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact Component={Home} />
          <Route path='/about' exact Component={About} />
          <Route path='/engagements' exact Component={Engagements} />
          <Route path='/media' exact Component={Media} />
          <Route path='/contact' exact Component={Contact} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
